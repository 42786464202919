@import '../../../styles/variables.scss';

.panelContainer,
.headerContainer {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
}

.headerTextContainer {
    text-align: center;
}

.backArrowContainer {
    margin-bottom: 20px;
}

.title {
    font-size: 24px;
    font-weight: 800;
    color: $primary-black;
    padding-bottom: 20px;
}

.subTitle {
    font-size: 20px;
    font-weight: 800;
    color: $primary-blue;
}

.detailContainer {
    display: flex;
    flex-direction: column;
}

.detailSubContainer {
    font-family: 'Lato';
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailHeading {
    font-size: 16px;
    font-weight: 800;
    color: $primary-black;
}

.detailRow {
    font-size: 16px;
    color: #6d6e71;
}

.bulletPoint {
    color: $primary-black;
    margin-right: 10px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    column-gap: 10%;
    row-gap: 20px;
}

.gridItem {
    cursor: pointer;
    height: 53px;
    border-bottom: 2px solid #222222;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gridItemText {
    font-family: 'Lato';
    font-weight: 800;
    font-size: 14px;
    color: #222222;
    width: 75%;
    text-align: left;
    text-transform: capitalize;
    word-break: keep-all;
    padding-left: 20px;
}

.gridItemServiceIcon {
    background: url('../../../static/img/popularServices/sample-service-icon.svg')
        no-repeat center;
    background-size: contain;
    width: 32px;
    height: 32px;
}

.gridItemCarrotIcon {
    background: url('../../../static/img/carrot.svg') no-repeat center;
    width: 32px;
    height: 32px;
}

.table {
    border-collapse: collapse;
    border: none;
    width: 100%;
}

.tableHeader {
    border: 1px solid #ddd;
    background-color: $primary-blue;
    color: white;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tableRow:nth-child(even),
.tableRowSelectable:nth-child(even) {
    background-color: #f2f2f2;
}

.tableRowSelectable:hover {
    background-color: #ddd;
    cursor: pointer;
}

.tableData {
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.modalIcon {
    float: right;
}

@media only screen and (min-width: 0px) and (max-width: 1400px) {
    .gridContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
    .gridContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
